export function FAQMove(){
    return(
        <section className="faq-part-2 ptb-100" id="section-FAQ-move">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading text-center pb-65" >
                        <h2 className="heading-title-2 pb-20"><span>Түгээмэл</span>&nbsp; асуулт хариулт</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 wow fadeInUp">
                    <ul className="nav nav-tabs Frequently-tabs pb-55" id="myTab" role="tablist">
                        <li>
                            <a className="active" data-toggle="tab" href="#general" role="tab">Бүгд</a>
                        </li>
                        <li>
                            <a data-toggle="tab" href="#ico" role="tab" >IEO</a>
                        </li>
                        <li>
                            <a data-toggle="tab" href="#Tokens" role="tab">Bitcoin & Satoshi</a>
                        </li>
                        <li>
                            <a data-toggle="tab" href="#client" role="tab">Mining & NFT</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 wow fadeInUp">
                    <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="general" role="tabpanel">
                              <div className="row">
                                  <div className="col-lg-6 col-md-12">
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">Крипто валют гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p>Блокчейн технологи дээр тулгуурлан бүтээгдсэн, цахим орчинд тоон хэлбэрээр байршиж байдаг цахим валют юм.  Мөн ямар нэг дундын зуучлагч хувь хүн, институт гэх зэрэг гуравдагч гишүүнгүй байдгаараа онцлогтой. Криптог тодорхой хэмжээний лимиттэйгээр компьютер, тусгай зориулалтын төхөөрөмж ашиглан программ хангамжийн тусламжтайгаар олборлон эзэмшдэг.  Одоогоор 1200 орчим  криптовалют бүртгэгдэн ОУ-ын улсын арилжааны платформууд дээр арилжаалагдаж байна. Нийт зах зээлийн багтаамж 150 тэрбум орчим ам доллароор хэмжигдэж байгаа ба биткойн нийт зах зээлийн 48% орчмыг дангаар эзэлдэг.</p>
                                            </div>
                                      </div>
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">Биткойн гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p>Энэ бүхэн анх 2008 оны арван нэгдүгээр сарын нэгэнд өөрийгөө Satoshi Nakamoto гэж нэрлэсэн нэгэн программист нөхрөөс үүдэлтэй юм. Тэрээр анх биткойны санааг гаргаж, загварыг боловсруулж, эх кодыг бичжээ. Түүний анхны санаа нь аливаа засгийн газраас хараат бус хүн болгоны хэрэглэж болох аливаа шилжүүлэг хийхэд дундын банк эсвэл гуравдагч этгээдийг ашиглах шаардлагагүй, шимтгэл төлөхгүй (маш бага) тийм мөнгөн тэмдэгтийг бүтээхийг хүссэн гэж тайлбарлаж байгаа юм. Nakamoto-г хэн ч хараагүй, хэн ч түүнтэй ярилцаж байгаагүй, түүнийг бодит хүн гэдэгт итгэх хүн бараг байхгүй. Анх 2010 онд 10000 биткойноор пицца худалдан авч байсан бөгөөд өнөөдрийн ханшаар  650 сая доллар юм. . Нийт 21'000'000 биткойн зах зээлд гаргасан байгаа бөгөөд  2140 онд  олборлож дуусна гэж үздэг байна.  Бага хэмжээний Биткойныг хэрэглээнд millibitcoin (mBTC), microbitcoin (µBTC) мөн Satoshi гэх нэгжээр хэмжинэ.</p>
                                            </div>
                                      </div>
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">Bitcoin mining буюу олборлолт гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p><span style={{textDecoration: "italic"}}>Төхөөрөмжөөр олборлох:</span>  Төхөөрөмжөөр олборлох гэдэг нь тооцоолон бодох чадвартай бүхий л төхөөрөмжүүдийг хэлж буй хэлбэр. Танай гэрийн компьютер, лаптоп тэр бүү хэл таны утас ч биткойныг олборлох боломжтой. Гагцхүү маш удаан хурдаар олборлоно. Анх биткойныг ердийн суурин компьютероор олборлодог байсан бол одоо үед зөвхөн биткойн олборлоход зориулсан төхөөрөмж хүртэл бий болсон. Уг төхөөрөмж нь ердийн компьютероос хэдэн арав зуу сая дахин хурдан олборлодог. <br/> <span>Үүлэн олборлолт хийх (Cloud Mining):</span>  Үүлэн олборлолт гэдэг нь төхөөрөмж авахгүйгээр биткойн олборлохыг хэлдэг. Энэ нь үүлэн олборлолтын үйлчилгээ үзүүлдэг баталгаатай вэбсайтуудаас биткойн олборлох хурд түрээсэлж авдаг ба уг хурдаараа биткойн олборлодог юм. Уг вэб сайтуудын ард маш том дата төв маягийн биткойн олборлодог төхөөрөмжүүд байгаа бөгөөд үүнийхээ тодорхой хэсгийг танд түрээслүүлж буй хэлбэр юм.</p>
                                            </div>
                                      </div>
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">Биткойн эзэмшилт гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p>Биткойныг эзэмшинэ гэдэг нь хэрэглэгч биткойнуудаа тодорхой хаягтайгаар холбогдох эрх юм. Тэгэхээр төлөгч нь заавал хувийн түлхүүрийн харгалзаатайгаар дижитал гарын үсгийг шилжүүлэгдээ хэрэглэх ёстой гэсэн үг. Хувийн түлхүүрийн ямар ч мэдлэггүй бол шилжүүлгийн гарын үсэг зурагдахгүй тул үрэгдэх боломжгүй юм. Сүлжээ нь нийтийн түлхүүрийг хэрэглэсэн гарын үсгээр баталгаажуулдаг.
                                                    <br/> Хэрвээ хувийн түлхүүрээ гээсэн тохиолдолд биткойны сүлжээнд ямар ч эзэмшигчийн нотолгоогүй болох бөгөөд ямар ч зохицуулах аргагүй болно, ингэснээр тухайн зооснууд нь хэрэглэх боломжгүйгээр алдагдах юм. Жишээлбэл 2013 онд нэгэн хэрэглэгч 7,500 биткойноо алдсан бөгөөд энэ тухайн үедээ 7,5 сая ам доллар гэсэн үг. Тэр залуу хувийн түлхүүрийг хадгалдаг хатуу дискээ алдсан юм.
                                                </p>
                                            </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">ICO болон IEO гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p> <span>ICO (Initial Coin Offering):</span>  ICO гэдэг нь шинээр криптовалют, цахим мөнгө гаргах, хөгжүүлэх зорилгоор олон нийтээс хөрөнгө оруулалт татах ажиллагааг хэлэх бөгөөд компанийн гаргаж буй койныг хүн бүр нээлттэй худалдан авах, солилцох боломжтой гэсэн үг. <br/> 
                                                    <span>IEO (Initial Exchange Offering):</span> IEO гэдэг нь зөвхөн крипто биржээр л дамжуулан оролцох боломжтой, хөрөнгө босгох арга хэрэгсэл. Эзэмшигчид  мөн бирж дээрээ солилцох, арилжих боломжтойгоороо давуу талтай.
                                                </p>
                                            </div>
                                      </div>
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">NFT гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p>Энэ нь англиар Non-Fungible-Token гэсэн үг. Fungible гэдэг нь монгол хэлнээ буулгавал орлуулшгүй гэсэн үг бол token гэдэг нь ямарваа нэгэн бараа материалыг илэрхийлэхдээ ашигладаг үг юм. Тэгэхээр Non-fungible-token гэдэг нь энгийнээр орлуулшгүй, цор ганц зүйл гэсэн утга болж байна.</p>
                                            </div>
                                      </div>
                                      <div className="accordion-faq-box mb-20">
                                        <a href="javascript:void(0)" className="accordion-faq-title">Сатоши гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p>Бага хэмжээний Биткойныг хэрэглээнд millibitcoin (mBTC), microbitcoin (µBTC) мөн Satoshi гэх нэгжээр хэмжинэ. Биткойны бүтээгчийг хүндэтгэн Биткойны хамгийн бага хэмжээг satoshi гэдэг ба энэ нь 0,00000001 биткойныг илэрхийлдэг</p>
                                            </div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                          <div className="tab-pane fade" id="ico" role="tabpanel">
                              <div className="row">
                                  <div className="col-lg-6 col-md-12">
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">ICO болон IEO гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p> <span>ICO (Initial Coin Offering):</span>  ICO гэдэг нь шинээр криптовалют, цахим мөнгө гаргах, хөгжүүлэх зорилгоор олон нийтээс хөрөнгө оруулалт татах ажиллагааг хэлэх бөгөөд компанийн гаргаж буй койныг хүн бүр нээлттэй худалдан авах, солилцох боломжтой гэсэн үг. <br/> 
                                                    <span>IEO (Initial Exchange Offering):</span> IEO гэдэг нь зөвхөн крипто биржээр л дамжуулан оролцох боломжтой, хөрөнгө босгох арга хэрэгсэл. Эзэмшигчид  мөн бирж дээрээ солилцох, арилжих боломжтойгоороо давуу талтай.
                                                </p>
                                            </div>
                                      </div>
                                    <div className="accordion-faq-box mb-20">
                                        <a href="javascript:void(0)" className="accordion-faq-title">Сатоши гэж юу вэ?</a>
                                        <div className="accordion-faq-content">
                                            <p>Бага хэмжээний Биткойныг хэрэглээнд millibitcoin (mBTC), microbitcoin (µBTC) мөн Satoshi гэх нэгжээр хэмжинэ. Биткойны бүтээгчийг хүндэтгэн Биткойны хамгийн бага хэмжээг satoshi гэдэг ба энэ нь 0,00000001 биткойныг илэрхийлдэг</p>
                                        </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">Bitcoin mining буюу олборлолт гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p> <span>Төхөөрөмжөөр олборлох:</span>  Төхөөрөмжөөр олборлох гэдэг нь тооцоолон бодох чадвартай бүхий л төхөөрөмжүүдийг хэлж буй хэлбэр. Танай гэрийн компьютер, лаптоп тэр бүү хэл таны утас ч биткойныг олборлох боломжтой. Гагцхүү маш удаан хурдаар олборлоно. Анх биткойныг ердийн суурин компьютероор олборлодог байсан бол одоо үед зөвхөн биткойн олборлоход зориулсан төхөөрөмж хүртэл бий болсон. Уг төхөөрөмж нь ердийн компьютероос хэдэн арав зуу сая дахин хурдан олборлодог.
                                                    <br/> <span>Үүлэн олборлолт хийх (Cloud Mining):</span>  Үүлэн олборлолт гэдэг нь төхөөрөмж авахгүйгээр биткойн олборлохыг хэлдэг. Энэ нь үүлэн олборлолтын үйлчилгээ үзүүлдэг баталгаатай вэбсайтуудаас биткойн олборлох хурд түрээсэлж авдаг ба уг хурдаараа биткойн олборлодог юм. Уг вэб сайтуудын ард маш том дата центер маягийн биткойн олборлодог төхөөрөмжүүд байгаа бөгөөд үүнийхээ тодорхой хэсгийг танд түрээслүүлж буй хэлбэр юм.
                                                </p>
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="tab-pane fade" id="Tokens" role="tabpanel">
                              <div className="row">
                                  <div className="col-lg-6 col-md-12">
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">Биткойн гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p>Энэ бүхэн анх 2008 оны арван нэгдүгээр сарын нэгэнд өөрийгөө Satoshi Nakamoto гэж нэрлэсэн нэгэн программист нөхрөөс үүдэлтэй юм. Тэрээр анх биткойны санааг гаргаж, загварыг боловсруулж, эх кодыг бичжээ. Түүний анхны санаа нь аливаа засгийн газраас хараат бус хүн болгоны хэрэглэж болох аливаа шилжүүлэг хийхэд дундын банк эсвэл гуравдагч этгээдийг ашиглах шаардлагагүй, шимтгэл төлөхгүй (маш бага) тийм мөнгөн тэмдэгтийг бүтээхийг хүссэн гэж тайлбарлаж байгаа юм. Nakamoto-г хэн ч хараагүй, хэн ч түүнтэй ярилцаж байгаагүй, түүнийг бодит хүн гэдэгт итгэх хүн бараг байхгүй. Анх 2010 онд 10000 биткойноор пицца худалдан авч байсан бөгөөд өнөөдрийн ханшаар  650 сая доллар юм. . Нийт 21'000'000 биткойн зах зээлд гаргасан байгаа бөгөөд  2140 онд  олборлож дуусна гэж үздэг байна.  Бага хэмжээний Биткойныг хэрэглээнд millibitcoin (mBTC), microbitcoin (µBTC) мөн Satoshi гэх нэгжээр хэмжинэ.</p>
                                            </div>
                                      </div>
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">Сатоши гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p>Бага хэмжээний Биткойныг хэрэглээнд millibitcoin (mBTC), microbitcoin (µBTC) мөн Satoshi гэх нэгжээр хэмжинэ. Биткойны бүтээгчийг хүндэтгэн Биткойны хамгийн бага хэмжээг satoshi гэдэг ба энэ нь 0,00000001 биткойныг илэрхийлдэг.</p>
                                            </div>
                                      </div>
                                    <div className="accordion-faq-box mb-20">
                                        <a href="javascript:void(0)" className="accordion-faq-title">Bitcoin mining буюу олборлолт гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p><span>Төхөөрөмжөөр олборлох:</span>Төхөөрөмжөөр олборлох гэдэг нь тооцоолон бодох чадвартай бүхий л төхөөрөмжүүдийг хэлж буй хэлбэр. Танай гэрийн компьютер, лаптоп тэр бүү хэл таны утас ч биткойныг олборлох боломжтой. Гагцхүү маш удаан хурдаар олборлоно. Анх биткойныг ердийн суурин компьютероор олборлодог байсан бол одоо үед зөвхөн биткойн олборлоход зориулсан төхөөрөмж хүртэл бий болсон. Уг төхөөрөмж нь ердийн компьютероос хэдэн арав зуу сая дахин хурдан олборлодог.
                                                <br/> <span>Үүлэн олборлолт хийх (Cloud Mining):</span>  Үүлэн олборлолт гэдэг нь төхөөрөмж авахгүйгээр биткойн олборлохыг хэлдэг. Энэ нь үүлэн олборлолтын үйлчилгээ үзүүлдэг баталгаатай вэбсайтуудаас биткойн олборлох хурд түрээсэлж авдаг ба уг хурдаараа биткойн олборлодог юм. Уг вэб сайтуудын ард маш том дата центер маягийн биткойн олборлодог төхөөрөмжүүд байгаа бөгөөд үүнийхээ тодорхой хэсгийг танд түрээслүүлж буй хэлбэр юм.
                                            </p>
                                            </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="accordion-faq-box mb-20">
                                        <a href="javascript:void(0)" className="accordion-faq-title">Крипто валют гэж юу вэ?</a>
                                        <div className="accordion-faq-content">
                                            <p>Блокчайн технологи дээр тулгуурлан бүтээгдсэн, цахим орчинд тоон хэлбэрээр байршиж байдаг цахим вальют юм.  Мөн ямар нэг дундын зуучлагч хувь хүн, институт гэх зэрэг 3дагч гишүүнгүй байдгаараа онцлогтой. Криптог тодорхой хэмжээний лимиттэйгээр компьютер, тусгай зориулалтын төхөөрөмж ашиглан программ хангамжийн тусламжтайгаар олборлон эзэмшдэг.  Одоогоор 1200 орчим  криптовалют бүртгэгдэн ОУ-ын улсын арилжааны платформууд дээр арилжаалагдаж байна. Нийт зах зээлийн багтаамж 150 тэрбум орчим ам доллараар хэмжигдэж байгаа ба биткойн нийт зах зээлийн 48% орчмыг дангаар эзэлдэг.</p>
                                        </div>
                                    </div>
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">Биткойн эзэмшилт гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p>Биткойныг эзэмшинэ гэдэг нь хэрэглэгч биткойнуудаа тодорхой хаягтайгаар холбогдох эрх юм. Тэгэхээр төлөгч нь заавал хувийн түлхүүрийн харгалзаатайгаар дижитал гарын үсгийг шилжүүлэгдээ хэрэглэх ёстой гэсэн үг. Хувийн түлхүүрийн ямар ч мэдлэггүй бол шилжүүлгийн гарын үсэг зурагдахгүй тул үрэгдэх боломжгүй юм. Сүлжээ нь нийтийн түлхүүрийг хэрэглэсэн гарын үсгээр баталгаажуулдаг.
                                                    <br/> Хэрвээ хувийн түлхүүрээ гээсэн тохиолдолд биткойны сүлжээнд ямар ч эзэмшигчийн нотолгоогүй болох бөгөөд ямар ч зохицуулах аргагүй болно, ингэснээр тухайн зооснууд нь хэрэглэх боломжгүйгээр алдагдах юм. Жишээлбэл 2013 онд нэгэн хэрэглэгч 7,500 биткойноо алдсан бөгөөд энэ тухайн үедээ 7,5 сая ам доллар гэсэн үг. Тэр залуу хувийн түлхүүрийг хадгалдаг хатуу дискээ алдсан юм.
                                                </p>
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="tab-pane fade" id="client" role="tabpanel">
                              <div className="row">
                                  <div className="col-lg-6 col-md-12">
                                      <div className="accordion-faq-box mb-20">
                                          <a href="javascript:void(0)" className="accordion-faq-title">Bitcoin mining буюу олборлолт гэж юу вэ?</a>
                                            <div className="accordion-faq-content">
                                                <p><span>Төхөөрөмжөөр олборлох:</span>Төхөөрөмжөөр олборлох гэдэг нь тооцоолон бодох чадвартай бүхий л төхөөрөмжүүдийг хэлж буй хэлбэр. Танай гэрийн компьютер, лаптоп тэр бүү хэл таны утас ч биткойныг олборлох боломжтой. Гагцхүү маш удаан хурдаар олборлоно. Анх биткойныг ердийн суурин компьютероор олборлодог байсан бол одоо үед зөвхөн биткойн олборлоход зориулсан төхөөрөмж хүртэл бий болсон. Уг төхөөрөмж нь ердийн компьютероос хэдэн арав зуу сая дахин хурдан олборлодог.
                                                    <br/> <span>Үүлэн олборлолт хийх (Cloud Mining):</span>  Үүлэн олборлолт гэдэг нь төхөөрөмж авахгүйгээр биткойн олборлохыг хэлдэг. Энэ нь үүлэн олборлолтын үйлчилгээ үзүүлдэг баталгаатай вэбсайтуудаас биткойн олборлох хурд түрээсэлж авдаг ба уг хурдаараа биткойн олборлодог юм. Уг вэб сайтуудын ард маш том дата центер маягийн биткойн олборлодог төхөөрөмжүүд байгаа бөгөөд үүнийхээ тодорхой хэсгийг танд түрээслүүлж буй хэлбэр юм.
                                                </p>
                                            </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="accordion-faq-box mb-20">
                                        <a href="javascript:void(0)" className="accordion-faq-title">NFT гэж юу вэ?</a>
                                        <div className="accordion-faq-content">
                                            <p>Энэ нь англиар Non-Fungible-Token гэсэн үг. Fungible гэдэг нь монгол хэлнээ буулгавал орлуулшгүй гэсэн үг бол token гэдэг нь ямарваа нэгэн бараа материалыг илэрхийлэхдээ ашигладаг үг юм. Тэгэхээр Non-fungible-token гэдэг нь энгийнээр орлуулшгүй, цор ганц зүйл гэсэн утга болж байна.</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
 </section>
    )
}