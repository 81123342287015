export default function Top (){
    return(
        <section className="sub-page-banner parallax" id="banner">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="page-banner text-center">
                        <h1 className="sub-banner-title">Мэдээ</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}