import Navbar from "./navbar";
import RelatedBlog from "./relatedBlog";
import Top from "./topSection";

export default function Blog2 (){
    return(
       <div>
        <Navbar/>
        <Top/>
         <section className="blog-detail-main ptb-100">
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="blog-detail-img">
                        <img style={{width: "100%"}} src="images/news-2.jpg" alt="blog"/>
                    </div>
                    <div className="blog-detail-content">
                        <ul>
                            <li>December 14,2021</li>
                            <li>by MDKI</li>
                        </ul>
                        <h2>Криптогийн талаар мэдэх хэрэгтэй зүйлс </h2>
                        <p><span style={{fontWeight: "bold"}}>Крипто валют:</span>   Блокчайн технологи дээр тулгуурлан бүтээгдсэн, цахим орчинд тоон хэлбэрээр байршиж байдаг цахим вальют юм.  Мөн ямар нэг дундын зуучлагч хувь хүн, институт гэх зэрэг гуравдагч гишүүнгүй байдгаараа онцлогтой. Криптог тодорхой хэмжээний лимиттэйгээр компьютер, тусгай зориулалтын төхөөрөмж ашиглан программ хангамжийн тусламжтайгаар олборлон эзэмшдэг.  Одоогоор 1200 орчим  криптовалют бүртгэгдэн ОУ-ын улсын арилжааны платформууд дээр арилжаалагдаж байна. Нийт зах зээлийн багтаамж 150 тэрбум орчим ам доллароор хэмжигдэж байгаа ба биткойн нийт зах зээлийн 48% орчмыг дангаар эзэлдэг. </p>
                        <p><span style={{fontWeight: "bold"}}>Биткойн:</span>   Анх 2008 оны арван нэгдүгээр сарын нэгэнд өөрийгөө Satoshi Nakamoto биткойны санааг гаргаж, загварыг боловсруулж, эх кодыг бичжээ. Аливаа засгийн газраас хараат бус хүн болгоны хэрэглэж болох аливаа шилжүүлэг хийхэд дундын банк эсвэл гуравдагч этгээдийг ашиглах шаардлагагүй, шимтгэл төлөхгүй (маш бага) тийм мөнгөн тэмдэгтийг бүтээх гол агуулгатай байсан бөгөөд үүнийг хөгжүүлсээр 2010 онд 10000 биткойноор пицца худалдан авч байсан. Энэ нь  өнөөдрийн ханшаар  650 сая доллар юм. . Нийт 21'000'000 биткойн зах зээлд гаргасан байгаа бөгөөд  2140 онд  олборлож дуусна гэж үздэг байна.   Бага хэмжээний Биткойныг хэрэглээнд millibitcoin (mBTC), microbitcoin (µBTC) мөн Satoshi гэх нэгжээр хэмжинэ.  Биткойны бүтээгчийг хүндэтгэн Биткойны хамгийн бага хэмжээг satoshi гэдэг ба энэ нь 0,00000001 биткойныг илэрхийлдэг. 1 Bitcoin = 100,000,000 Satoshi </p>
                        <p><span style={{fontWeight: "bold"}}>Биткойн эзэмшилт:</span>   Биткойныг эзэмшинэ гэдэг нь хэрэглэгч биткойнуудаа тодорхой хаягтайгаар холбогдох эрх юм. Тэгэхээр төлөгч нь заавал хувийн түлхүүрийн харгалзаатайгаар дижитал гарын үсгийг шилжүүлэгдээ хэрэглэх ёстой гэсэн үг. Хувийн түлхүүрийн ямар ч мэдлэггүй бол шилжүүлгийн гарын үсэг зурагдахгүй тул үрэгдэх боломжгүй юм. Сүлжээ нь нийтийн түлхүүрийг хэрэглэсэн гарын үсгээр баталгаажуулдаг. Хэрвээ хувийн түлхүүрээ гээсэн тохиолдолд биткойны сүлжээнд ямар ч эзэмшигчийн нотолгоогүй болох бөгөөд ямар ч зохицуулах аргагүй болно, ингэснээр тухайн зооснууд нь хэрэглэх боломжгүйгээр алдагдах юм. Жишээлбэл 2013 онд нэгэн хэрэглэгч 7,500 биткойноо алдсан бөгөөд энэ тухайн үедээ 7,5 сая ам доллар гэсэн үг. Тэр залуу хувийн түлхүүрийг хадгалдаг хатуу дискээ алдсан юм.</p>
                        <p><span style={{fontWeight: "bold"}}>ICO (Initial Coin Offering): </span>   ICO гэдэг нь шинээр криптовалют, цахим мөнгө гаргах, хөгжүүлэх зорилгоор олон нийтээс хөрөнгө оруулалт татах ажиллагааг хэлэх бөгөөд компанийн гаргаж буй койныг хүн бүр нээлттэй худалдан авах, солилцох боломжтой гэсэн үг. </p>

                        <p><span style={{fontWeight: "bold"}}>ICO (Initial Coin Offering): </span>   гэх ойлголт байх агаад энэ нь зөвхөн крипто биржээр л дамжуулан оролцох боломжтой, хөрөнгө босгох арга хэрэгсэл. Эзэмшигчид  мөн бирж дээрээ солилцох, арилжих боломжтойгоороо давуу талтай</p>
                         <blockquote>Bitfury компани биткойн олборлоох төхөөрөмж үйлдвэрлэдэг бөгөөд хүчин чадал, үзүүлэлтээрээ дэлхийд тэргүүлэгч юм. </blockquote>
                        <p><span style={{fontWeight: "bold"}}>NFT: </span>   Энэ нь англиар Non-Fungible-Token гэсэн үг. Fungible гэдэг нь монгол хэлнээ буулгавал орлуулшгүй гэсэн үг бол token гэдэг нь ямарваа нэгэн бараа материалыг илэрхийлэхдээ ашигладаг үг юм. Тэгэхээр Non-fungible-token гэдэг нь энгийнээр орлуулшгүй, цор ганц зүйл гэсэн утга болж байна.</p>
                    </div>
                </div>
            </div>
            <RelatedBlog/>
            </div>
        </section>
       </div>
    )
}